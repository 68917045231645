<template>
  <div class="wrap">
    <Navbar :title="$t('newTab.bettingRecord')" fixed>
      <template #right>
        <div class="right_time" @click="openModel">
          {{ $t(rightList[timeValue].title) }}
          <van-icon name="arrow-down" color="var(--light)" size="20" />
        </div>
        <PopModel :list="rightList" ref="popRef" @submit="setList">
          <template #title="{ item }">
            {{ $t(item.title) }}
          </template>
        </PopModel>
      </template>
    </NavBar>
    <div class="content">
      <div class="tab">
        <div class="tab_list" :class="tabIndex == index ? 'active' : ''" v-for="item, index in tabList" :key="index"
          @click="tabClick(item, index)">
          {{ $t(item.title) }}
        </div>
      </div>
      <div class="list">
        <List url="/lottery/order" ref="listRef"
          :initParams="{ status: tabList[tabIndex].value, time: rightList[timeValue].value, win: tabList[tabIndex].win || '', type: type }">
          <template #item="{ item }">
            <div class="good_content">
              <div class="list_item">
                <div class="bottom">
                  <div class="left">
                    <img v-if="merchants.find((mitem) => mitem.type === item.type)" :src="merchants.find((mitem) => mitem.type === item.type).picture
                      " alt="" />
                  </div>
                  <div class="right">
                    <div class="title">
                      <span class="label">{{ $t("orderDetails.title.expect") }}:&nbsp;</span>
                      <span class="value">{{ item.expect }}</span>
                    </div>
                    <div class="title">
                      <span class="label">{{ $t("order.typeTitle") }}:&nbsp;</span>
                      <span class="value">{{ item.typeCn }}</span>
                    </div>
                    <div class="price">
                      <span class="label">{{ $t("order.totalPrice") }}:&nbsp;</span>
                      <span class="value">{{ item.totalMoney }}</span>
                    </div>
                    <div class="win">
                      <span class="label">{{ $t("orderDetails.title.inType") }}: &nbsp;</span>
                      <span class="value">{{ item.code }}</span>
                    </div>
                    <div class="win">
                      <span class="label">{{ $t("order.winPrice") }}: &nbsp;</span>
                      <span class="value">{{ item.winMoney }}</span>
                    </div>
                    <div class="status">
                      <span class="label">{{ $t("order.status") }}:&nbsp;</span>
                      <span class="ball_wrap" v-if="item.status == '已开奖'">
                        <div class="ball" v-for="(item, index) in item?.open?.dwd" :key="index">{{ item }}</div>
                      </span>
                      <span class="value" v-else>{{ getStatus(item) }}</span>
                    </div>
                    <!-- <div class="status number" v-else>
                      <span class="label">{{ $t("order.status") }}:&nbsp;</span>
                      <span class="ball_wrap" v-if="item.status == '已开奖'">
                        <div class="imgs">
                          <img v-for="val, num in item?.open?.dwd" :key="num"
                            :src="require(`@/assets/icon/dice/${val}.png`)" alt="">
                        </div>
                      </span>
                      <span class="value" v-else>{{ getStatus(item) }}</span>
                    </div> -->
                    <div class="time">{{ dateEvent(item.indate) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </List>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/JNav";
import PopModel from "@/components/pop"
import { Icon } from "vant";
import List from "@/components/List"
import { formatDate } from "@/utils/tools";

export default {
  components: {
    Navbar,
    PopModel,
    [Icon.name]: Icon,
    List
  },
  data() {
    return {
      rightList: [{
        title: 'game.all',
        value: ''
      }, {
        title: 'betting.today',
        value: 'today'
      }, {
        title: 'betting.yesterday',
        value: 'yesterday'
      }, {
        title: 'betting.oneWeek',
        value: 'week'
      }],
      timeValue: 0,
      tabList: [{
        title: 'game.all',
        value: 0
      }, {
        title: 'betting.waitingPrize',
        value: 1
      }, {
        title: 'order.status.profit',
        value: 2,
        win: 1,
      }, {
        title: 'order.status.loss',
        value: 2,
        win: 2
      }
        //  {
        //   title: 'betting.dontWin',
        //   value: 3
        // }, {
        //   title: 'betting.canceled',
        //   value: 4
        // }
      ],
      tabIndex: 0,
      type: ''
    }
  },
  created() {
    const { type } = this.$route.query;
    this.type = type;
  },
  computed: {
    merchants() {
      return this.$store.getters.AllGameList || [];
    }
  },
  methods: {
    dateEvent(indate) {
      return formatDate("Y-m-d H:i:s", indate);
    },
    getStatus(item) {
      if (!item) return;
      const { status, open } = item;
      switch (status) {
        case "待开奖":
          return this.$t("status.waiting");
          break;
        case "已开奖":
          const data = open["hz-hz-dxds"] || (open.dxds && open.dxds[0]) || {};
          let res = "";
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const value = data[key];
              res += "\n" + this.getStatusText(value);
            }
          }
          return res;
          break;
        case "开奖出错":
          return this.$t("status.fail");
          break;
        default:
          return "";
          break;
      }
    },
    getStatusText(text) {
      switch (text) {
        case "大":
          return this.$t("open.big");
          break;
        case "小":
          return this.$t("open.small");
        case "单":
          return this.$t("open.single");
        case "双":
          return this.$t("open.double");
        default:
          return text;
      }
    },
    openModel() {
      this.$refs.popRef && this.$refs.popRef.open()
    },
    setList({ index }) {
      this.timeValue = index;
      this.$refs.popRef && this.$refs.popRef.close();
      this.$nextTick(() => {
        this.$refs.listRef && this.$refs.listRef.handleRefresh()
      })
    },
    tabClick(item, index) {
      this.tabIndex = index;
      this.$nextTick(() => {
        this.$refs.listRef && this.$refs.listRef.handleRefresh()
      })
    }
  }
}
</script>
<style lang="less" scoped>
@Color: var(--theme);
@comRadius: 10px;
@comBG: #fff;
@wrapPadding: calc(12rem / 16);

.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.wrap {
  min-height: calc(100vh - 50px);

  .right_time {
    font-size: calc(20rem / 16);
    color: var(--light);
    margin-left: calc(5rem / 16);
  }

  .content {
    padding: 0 calc(10rem / 16);

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--textColor);
      border-radius: calc(5rem / 16);
      height: calc(40rem / 16);
      overflow: hidden;
      background: var(--light);
      margin-top: calc(10rem / 16);

      &_list {
        height: calc(40rem / 16);
        flex: 0 0 25%;
        color: var(--textColor);
        font-size: calc(13rem / 16);
        transition: all .5s linear;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(15rem / 16);
        text-align: center;
        background: var(--light);
      }

      .active {
        background: var(--red);
        color: var(--light);
      }
    }

    .ball_wrap {
      .ball {
        text-indent: 0;
        text-align: center;
        border-radius: 50%;
        width: calc(26rem / 16);
        line-height: calc(26rem / 16);
        height: calc(26rem / 16);
        margin: 1px;
        background: var(--theme);
        color: #000;
        display: inline-block;
        background-image: url("~@/assets/icon/game_ball.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .number {
      display: flex;
      align-items: center;

      .imgs {
        display: flex;
        align-items: center;
        gap: calc(10rem / 16);

        img {
          width: calc(28rem / 16);
          height: calc(28rem / 16);
          display: block;
        }
      }
    }

    .good_content {
      height: 100%;
      // padding: 0 calc(16rem / 16);

      .list_item {
        padding: calc(8rem / 16) 0;
      }

      .bottom {
        width: 100%;
        display: flex;
        align-items: center;
        line-height: 1;
        background-color: var(--light);
        box-shadow: 0 1px 4px 0 var(--light);
        border-radius: calc(4rem / 16);

        .left {
          // max-width: 40%;
          // width: 24vw;
          // height: 24vw;
          padding: 0 2vw;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 20vw;
            height: 20vw;
            border-radius: 2px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          // align-items: flex-end;
          justify-content: space-between;
          margin-left: calc(20rem / 16);
          // max-width: 60%;
          font-size: calc(14rem / 16);
          padding: calc(10rem / 16);
          flex-grow: 1;

          div {
            margin-top: 4px;

            &:nth-child(1) {
              margin-top: 0;
            }
          }

          .label {
            color: var(--textColor);
          }

          .value {
            color: var(--textColor);
          }

          .return {
            color: @Color;
            font-size: calc(14rem / 16);
            margin-bottom: calc(12rem / 16);
          }

          .desc {
            color: var(--textColor);
            font-size: calc(12rem / 16);
            margin-bottom: calc(38rem / 16);
            max-width: 100%;
            word-break: break-all;
          }

          .time {
            color: var(--colors);
            font-size: calc(12rem / 16);
          }
        }
      }
    }
  }
}
</style>